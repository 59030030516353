<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-2 d-flex flex-column flex-lg-row flex-column-fluid bg-dark"
      :class="{ 'login-signup-on': this.state == 'signup' }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden">
        <!--begin: Aside Container-->
        <div class="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-35">
          <!--begin::Logo-->
          <!-- <a href="#" class="text-center pt-2">
            <img src="assets/media/logos/logo.png" class="max-h-75px" alt="" />
          </a> -->
          <!--end::Logo-->
          <!--begin::Aside body-->
          <div class="d-flex flex-column-fluid flex-column flex-center">
            <!--begin::Signup-->
            <div class="login-form login-signup pt-11">
              <!--begin::Form-->
              <form class="form" novalidate="novalidate" id="kt_login_signup_form">
                <div class="text-center pb-8" v-if="loading">
                  <h2 class="font-weight-bolder text-white font-size-h2 font-size-h1-lg">กำลังยืนยันข้อมูลระบบ รอสักครู่</h2>
                  <p class="text-muted font-weight-bold font-size-h4">กรุณาอย่าปิดหน้านี้</p>
                </div>
                <!--begin::Title-->
                <div class="text-center pb-8" v-if="complete_step">
                  <h2 class="font-weight-bolder text-white font-size-h2 font-size-h1-lg">ยินดีต้อนรับ {{ form.email }}</h2>
                  <p class="text-muted font-weight-bold font-size-h4">ระบุรหัสผ่านใหม่สำหรับผู้ใช้นี้</p>
                </div>
                <!--end::Title-->
                <!--begin::Form group-->
                <div class="form-group" v-if="complete_step">
                  <label class="font-size-h6 font-weight-bolder text-white">Password</label>
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group" v-if="complete_step">
                  <label class="font-size-h6 font-weight-bolder text-white">Confirm password</label>
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="confirmpassword"
                    ref="confrimpassword"
                    v-model="form.confirmpassword"
                    autocomplete="off"
                  />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <!-- <div class="form-group">
                  <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />I Agree the
                  <a href="#">terms and conditions</a>.
                  <span></span></label>
                </div> -->
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3" v-if="complete_step">
                  <button type="button" id="kt_login_signup_submit" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4" v-bind:class="btn.signup_submit" v-on:click="callComplete">Complete Register</button>
                </div>
                <!--end::Form group-->
                <div class="form-group text-center" v-if="complete_next">
                  <h2 class="font-weight-bolder text-white font-size-h2 font-size-h1-lg">Welcome {{ form.email }}</h2>
                  <div class="h5 text-success">การตั้งรหัสผ่านเสร็จสิ้น</div>
                  <router-link to="/dashboard">
                    <button type="button" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4">ไปยังหน้า dashboard</button>
                  </router-link>
                </div>
                <div class="form-group text-center" v-if="complete_existed">
                  <h2 class="font-weight-bolder text-white font-size-h2 font-size-h1-lg">Welcome {{ username }}</h2>
                  <div class="h5 text-success" v-if="nopassword">คุณยังไม่ได้ตั้งรหัสผ่าน อย่าลืมไปตั้งด้วยล่ะ</div>
                  <router-link to="/dashboard">
                    <button type="button" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4">ไปยังหน้า dashboard</button>
                  </router-link>
                </div>
                <div class="form-group h5 text-center text-danger" v-if="complete_error">
                  <div>{{ error_text }}</div>
                  <div>
                    <button type="button" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4" v-on:click="registeragain">เข้าสู่ระบบอีกครั้ง</button>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Signup-->
          </div>
          <!--end::Aside body-->
        </div>
        <!--end: Aside Container-->
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div class="content order-1 order-lg-2 d-flex flex-column w-100 pb-0" style="background-color: #2a3040;">
        <!--begin::Title-->
        <div class="d-flex flex-column justify-content-center text-center pt-lg-40 pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7">
          <!--begin::Logo-->
          <div class="text-center pt-2">
            <img src="/images/logo2.png" style="height: 80px" />
          </div>
          <!--end::Logo-->
          <router-link to="/" class="font-size-h3 display4 font-weight-bolder my-7 text-white">Tryfree Esports</router-link>
          <p class="font-weight-bolder font-size-h2-md font-size-lg text-white opacity-70">ร่วมทายผลฟรี ลุ้นรับรางวัลสุดยิ่งใหญ่ กับทายฟรี อีสปอร์ต</p>
        </div>
        <!--end::Title-->
        <!--begin::Image-->
        <div class="content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" style="background-image: url('images/banner2.png');"></div>
        <!--end::Image-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-2.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import JwtService from "@/core/services/jwt.service";

import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: "login-1",
  data() {
    return {
      state: "signup",
      form: {
        email: "",
        password: "",
        confirmpassword: ""
      },
      btn: {
        signup_submit: []
      },
      loading: false,
      complete_step: false,
      complete_next: false,
      complete_existed: false,
      complete_error: false,
      username: '',
      nopassword: false,
      error_text: ''
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    const signup_form = KTUtil.getById("kt_login_signup_form");

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.loading = true
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        this.loading = false
        this.complete_error = true
        this.error_text = 'Please provide your email for confirmation'
        return
      }

      // console.log('emailForSignIn', email)

      // The client SDK will parse the code from the link for you.
      firebase.auth().signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // console.log('signInWithEmailLink', result)

          this.form.email = email

          this.preRegister()
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          this.loading = false
          this.complete_step = false
          this.complete_error = true
          this.error_text = `${error.code} : ${error.message}`
          // console.error('signInWithEmailLink', error)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    } else {
      console.error('no email provide')
      this.loading = false
      this.complete_step = false
      this.complete_error = true
      this.error_text = 'No provided e-mail'
    }
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    preRegister () {
      firebase.auth().currentUser.getIdToken(true).then(idToken => {
        // console.log('idToken', idToken)
        window.localStorage.setItem('tfbtoken', idToken);

        // Send to register
        var body = {
          email: this.form.email,
          token: idToken
        }

        this.axios.post('/register/email', body).then(result => {
          console.log('pre-register complete', result.data)

          this.loading = false
          var data = result.data
          if (data.registered) {
            this.complete_existed = true
            this.nopassword = data.password || false
            this.username = data.name
          } else {
            this.complete_step = true
          }

          this.complete_error = false

          JwtService.saveToken(result.data.token)
        })
        .catch((error) => {
          var { code, message } = error.response.data
          
          this.loading = false
          this.complete_step = false
          this.complete_error = true
          this.error_text = `${code} : ${message}`
        })
      })
      .catch(() => {
        this.loading = false
      })
    },
    callComplete() {
      this.btn.signup_submit = ["spinner", "spinner-light", "spinner-right"]
      this.axios.post('/auth/changepassword', this.form).then(result => {
        this.complete_step = false
        this.complete_error = false
        this.complete_next = true

        // setTimeout(() => {
        //   this.$router.push(`/dashboard`)
        // }, 1500)
      })
    },
    registeragain() {
      this.$router.push('/register')
    }
  }
};
</script>
